.skeleton {
  animation: shimmer 1s linear infinite alternate;
  -webkit-animation: shimmer 1s linear infinite alternate;
  -moz-animation: shimmer 1s linear infinite alternate;
  -o-animation: shimmer 1s linear infinite alternate;
}

@keyframes shimmer {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
